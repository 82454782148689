<template>
  <el-main>
    <el-form class="el-form-search" label-width="110px">
      <el-form-item label="用户昵称：">
        <el-input size="small" v-model="ruleForm.nickname" placeholder="请输入用户昵称"></el-input>
      </el-form-item>
      <el-form-item label="用户手机号：">
        <el-input size="small" v-model="ruleForm.mobile" placeholder="请输入用户手机号"></el-input>
      </el-form-item>
      <el-form-item label="提现单号：">
        <el-input size="small" v-model="ruleForm.withdraw_no" placeholder="请输入提现单号"></el-input>
      </el-form-item>
      <el-form-item label="审核状态：">
        <el-select size="small" v-model="ruleForm.status" placeholder="请选择">
          <el-option v-for="item in statusList" :key="item.status" :label="item.name" :value="item.status"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="提现时间：">
        <el-date-picker v-model="ruleForm.time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="30px">
        <el-button type="primary" size="small" @click="getShopList('search')">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="withdraw_no" label="提现单号" align="center"></el-table-column>
      <el-table-column label="用户信息" align="center">
        <template v-slot="{ row }">
          <UserInfo :name="row.nickname" :avatar="row.avatar"></UserInfo>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" label="手机号" align="center"></el-table-column>
      <el-table-column label="提现金额" align="center">
        <template v-slot="{ row }">￥{{ row.amount }}</template>
      </el-table-column>
      <el-table-column label="提现时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column prop="bank_card" label="银行卡号" align="center"></el-table-column>
      <el-table-column prop="bank_info" label="银行信息" align="center"></el-table-column>
      <el-table-column label="结算状态" align="center">
        <template v-slot="{ row }">
          {{ row.status == -1 ? '已驳回' : row.status == 1 ? '待审核' : row.status == 2 ? '审核通过' : '订单异常' }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button type="text" v-if="row.status == 1" @click="audit(row)">审核</el-button>
          <el-button type="text" @click="log(row)">记录</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="审核" :visible.sync="dislogAudit" width="600px">
      <el-form ref="form" :model="auditForm" :rules="rules" label-width="110px">
        <el-form-item label="审核结果：">
          <el-radio-group v-model="auditForm.status">
            <el-radio :label="2">通过审核并打款</el-radio>
            <el-radio :label="-1">驳回</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="收款人：">{{auditLogInfo.bank_name}}</el-form-item>
        <el-form-item label="银行信息：">{{auditLogInfo.bank_info}}</el-form-item>
        <el-form-item label="银行卡号：">{{auditLogInfo.bank_card}}</el-form-item>
        <el-form-item label="打款金额：" prop="remit_amount">
          <el-input v-model="auditForm.remit_amount"></el-input>
        </el-form-item>
        <el-form-item v-if="auditForm.status == -1" label="驳回原因：" prop="remark">
          <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="auditForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dislogAudit = !1">取 消</el-button>
        <el-button type="primary" @click="handleAudit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="记录" :visible.sync="dislogLog" width="600px">
      <el-form ref="form" :model="auditForm" :rules="rules" label-width="120px">
        <el-form-item label="审核结果：">
          {{ auditLogInfo.status == -1 ? '已驳回' : auditLogInfo.status == 1 ? '待审核' : '已通过' }}
        </el-form-item>
        <el-form-item label="推广员名称：">
          {{ auditLogInfo.nickname }}
        </el-form-item>
        <el-form-item label="打款银行：">
          {{ auditLogInfo.bank_info }}
        </el-form-item>
        <el-form-item label="银行卡号：">
          {{ auditLogInfo.bank_card }}
        </el-form-item>
        <el-form-item v-if="auditLogInfo.status == 2" label="打款金额：">
          {{ auditLogInfo.remit_amount }}
        </el-form-item>
        <el-form-item v-if="auditLogInfo.status == -1" label="驳回原因：">
          {{ auditLogInfo.remark }}
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dislogLog = !1">取 消</el-button>
        <el-button type="primary" @click="handleAudit">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import UserInfo from '@/components/userInfo';
import { getDateformat } from '@/util/getDate.js';
import Paging from '@/components/paging';
export default {
  components: {
    UserInfo,
    Paging,
  },
  data() {
    return {
      getDateformat,
      ruleForm: {
        nickname: '',
        mobile: '',
        withdraw_no: '',
        status: 0,
        time: [],
      },
      auditForm: {
        id: '',
        status: 2,
        remit_amount: '',
        remark: '',
      },
      statusList: [
        { name: '全部', status: 0 },
        { name: '已驳回', status: -1 },
        { name: '待审核', status: 1 },
        { name: '审核通过', status: 2 },
      ],
      rules: {
        remit_amount: { required: true, message: '请填写打款金额', trigger: 'blur' },
        remark: { required: true, message: '请填写驳回原因', trigger: 'blur' },
      },
      auditLogInfo: {},
      page: 1,
      rows: 10,
      total_number: 0,
      dataList: [],
      dislogAudit: !1,
      dislogLog: !1,
    };
  },
  created() {
    this.getShopList();
  },
  methods: {
    cancelSearch() {
      this.ruleForm = {
        nickname: '',
        mobile: '',
        withdraw_no: '',
        status: 0,
        time: [],
      };
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getShopList();
    },
    audit(row) {
      this.dislogAudit = !0;
      this.auditLogInfo = row;
      this.auditForm.id = row.id;
      this.auditForm.status = 2;
      this.auditForm.remark = '';
    },
    log(row) {
      this.auditLogInfo = row;
      this.dislogLog = !0;
    },
    handleAudit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$axios.post(this.$api.O2O.finance.audit, this.auditForm).then(res => {
            if (res.code == 0) {
              this.$message.success('处理成功');
              this.dislogAudit = !1;
              this.getShopList();
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    getShopList(style) {
      if (style) {
        this.page = 1;
      }
      let obj = {
        page: this.page,
        rows: this.rows,
      };
      if (this.ruleForm.nickname) {
        obj.nickname = this.ruleForm.nickname;
      }
      if (this.ruleForm.mobile) {
        obj.mobile = this.ruleForm.mobile;
      }
      if (this.ruleForm.withdraw_no) {
        obj.withdraw_no = this.ruleForm.withdraw_no;
      }
      if (this.ruleForm.status) {
        obj.status = this.ruleForm.status;
      }
      if (this.ruleForm.time && this.ruleForm.time.length === 2) {
        obj.start_time = Math.floor(this.ruleForm.time[0] / 1000);
        obj.end_time = Math.floor(this.ruleForm.time[1] / 1000);
        if (obj.start_time === obj.end_time) {
          obj.end_time = obj.start_time + 60 * 60 * 24 - 1;
        }
      }
      this.$axios.post(this.$api.O2O.finance.withdrawList, obj).then(res => {
        if (res.code === 0) {
          this.dataList = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
}
</style>
